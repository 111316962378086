/* Container for the entire gallery */
.gallery {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-size: 2vh;
    padding-top: 13vh;
    background-color: rgb(255, 255, 255);
}

/* Headings for project details */
h2 {
    all: unset;
    font-size: 1.8vh;
    letter-spacing: 0.1rem;
    font-weight: bold;
    text-align: center;
    line-height: 1.6;
    width: 50%;
}

/* Scroller container for images */
.scroller {
    height: 100%;
    display: flex;
    width: 100vw;
    object-fit: cover;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 3vh;
    overflow-x: scroll; /* Enable horizontal scrolling */
    white-space: nowrap;
    scroll-snap-type: x mandatory; /* Enable scroll snapping */
}

/* Container for each image in the scroller */
.scroller .image-container {
    height: auto;
    width: 100vw;
    margin: 0% 0 10% 0;
}

/* Footnotes within the text section */
.gallery .text .footnotes {
    font-size: 1.5vh;
}

/* Description for each image */
.gallery .image-description {
    margin: 1% 0 0% 0;
    font-size: 1.7vh;
    opacity: 1;
}

/* Hide the default scrollbar */
::-webkit-scrollbar {
    display: none;
}

/* Styling for the text section */
.text p {
    width: 50vw;
    height: auto;
    line-height: 1.6;
    text-align: left;
    margin-top: 10%;
}

/* Styling for images within the scroller */
.scroller img {
    max-height: 75vh;
    max-width: 57vw;
    cursor: pointer;
}

/* Styling for the video container */
.video-container {
    width: 100vw;
    height: 100vh;
}

/* Styling for videos within the video container */
iframe {
    padding-top: 13vh;
    height: 80vh;
    width: 80vw;
}

/* Styling for video descriptions */
.video-description {
    margin: 1% 0 0% 0;
    font-size: 1.7vh;
}

/* Styling for links */
.gallery a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

/* Hover effect for links */
.gallery a:hover {
    cursor: pointer;
}

/* Styling for the project title */
.gallery .project-title {
    font-style: italic;
}

/* Styling for the text section */
.gallery .text {
    width: 50%;
    font-size: 2vh;
    text-align: justify;
    line-height: 1.7;
    margin: 5% 0% 8% 0%;
}
/*-------------------------------------------------------------*/
/*---------------------------modal-----------------------------*/
/*-------------------------------------------------------------*/

.gallery .modal {
    background-color: white;
}

.gallery .description {
    color: #000000;
}

/* Media query for phones (max-width: 600px) */
@media screen and (max-width: 600px) {
    .scroller img {
        max-height: 100%;
        max-width: 98vw;
        cursor: pointer;
    }
    .gallery .text {
        width: 90%;
        font-size: 2vh;
        text-align: justify;
        line-height: 1.7;
        margin: 5% 0% 8% 0%;
    }
    iframe {
        padding-top: 1vh;
        padding-bottom: 5vh;
        max-height: 30vh;
        width: 98vw;
    }
    .video-container {
        width: 100vw;
        height: auto;
    }
    h2 {
        width: 80vw;
    }
}
