@font-face {
    font-family: joanna-regular;
    src: url(/fonts/unicode.joanna.ttf);
}
@font-face {
    font-family: arial-mt;
    src: url(/fonts/Arial\ MT\ Regular.otf);
}

@font-face {
    font-family: arial-rounded;
    src: url(/fonts/Arial\ Rounded\ MT\ Regular.ttf);
}

body {
    margin: 0;
    font-family: "joanna-regular";

    background-color: rgb(255, 255, 255);
    color: black;
    box-sizing: border-box;
}
