/*-------------------------------------------------------------*/
/*---------------------------modal-----------------------------*/
/*-------------------------------------------------------------*/

.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: row;
}

/*---------------------------buttons-----------------------------*/

.prev-button {
    position: fixed;
    top: 50%;
    left: 5vw;
    transform: translateY(-50%);
    color: rgb(148, 148, 148);
    cursor: pointer;
    z-index: 15;
}

.next-button {
    position: fixed;
    top: 50%;
    right: 5vw;
    transform: translateY(-50%);
    color: rgb(148, 148, 148);
    cursor: pointer;
    z-index: 15;
}
.close-button {
    position: absolute;
    top: 2rem;
    right: 3rem;
    background-color: transparent;
    border: none;
    color: rgb(148, 148, 148);
    font-size: 1rem;
    cursor: pointer;
    z-index: 15;
}

/*---------------------------contents-----------------------------*/

.modal-content {
    position: relative;
    max-width: 80vw;
    max-height: 100vh;
    overflow: auto;
    padding: 20px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.fullScreenImage {
    max-height: 90vh;
    max-width: 80vw;
    width: auto;
    position: relative;
}
.fullScreenImage img {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Remove focus outline on buttons */
.fullScreenImage:focus {
    outline: none;
}

.description {
    margin-top: 1rem;
}

/* Media query for phones (max-width: 600px) */
@media screen and (max-width: 600px) {
    .modal-content {
        position: relative;
        max-width: 100vw;
        max-height: 100vh;
        overflow: auto;
        padding: 0px;
        text-align: center;
        -webkit-tap-highlight-color: transparent;
    }
    .fullScreenImage {
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 70vh;
        max-width: 98vw;
        width: auto;
        position: relative;
    }

    .prev-button {
        top: 90%;
    }

    .next-button {
        top: 90%;
    }

    .close-button {
        top: 2rem;
        right: 1rem;
    }
}
