.App {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    background-color: rgb(255, 255, 255);
}

/*-------------------------------------------------------------*/
/*--------------------------- default image -------------------*/
/*-------------------------------------------------------------*/
.App .default-image-container {
    display: flex;
    margin-top: 14vh;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.App .default-images img {
    max-height: 75vh;
    max-width: 90vw;
    object-fit: cover;
}

.App .image-description {
    margin: 4vh 0 0% 0;
    font-size: 1.7vh;
    opacity: 0;
    transition: opacity 0.3s ease;
}

/* Show the image description when hovering over the .default-images container */
.default-images:hover .image-description {
    opacity: 1;
}
