.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    padding-top: 10vh;
    overflow-y: auto;
    z-index: 0;
    background-color: rgb(255, 254, 254);
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.project-title {
    font-size: 2.1vh;
    font-weight: lighter;
    font-family: "joanna-regular";
}

.projects li {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    width: 100vw;
    height: 100%;
    box-sizing: border-box;
}

.projects li::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 95%; /* Adjust the width to control the coverage of the border */
    height: 0.5px;
    background-color: black;
}

.project {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    width: 100%;
    height: 50vh;
    box-sizing: border-box;
}

.projects li img {
    box-sizing: border-box;
    opacity: 1;
    padding: 0 10% 0 10%;
    width: 60%;
}

.projects li img:hover {
    opacity: 0.9;
}

.projects li p {
    all: unset;
    font-size: 1.4vh;
    letter-spacing: 0.1rem;
    font-weight: bold;
    text-align: center;
    line-height: 1.6;
    font-family: "arial-mt";
    width: 50%;
    padding: 0 7% 0 7%;
}

.projects li p:hover {
    opacity: 0.8;
}

/* Media query for phones (max-width: 600px) */
@media screen and (max-width: 600px) {
    .projects li {
        padding: 20px; /* Adjust padding for better mobile layout */
        flex-direction: column; /* Change flex-direction to column for better layout */
    }

    .project {
        flex-direction: column;
        justify-content: space-evenly;

        padding: 0;
    }
    .projects li p {
        width: 80vw;
        padding-bottom: 1vh;
    }
    .projects li img {
        box-sizing: border-box;
        opacity: 1;
        padding: 0;
        width: 100%;
    }
}
