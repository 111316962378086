#iku-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    overflow: scroll;
    font-size: 2vh;
    background-color: #000000;
}

.image-grid {
    margin-top: 10vh;
    width: 90vw;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-auto-flow: row dense;
    grid-gap: 2rem;
    padding: 5rem;
    overflow: scroll;
    align-items: space-evenly;
    justify-content: center;
}

.image-grid img {
    max-height: 100%;
    max-width: 100%;

    /* cursor: pointer;*/
    opacity: 1;
}

.image-grid img:hover {
    transform: scale(1.005);
    opacity: 0.95;
}

.horizontal {
    grid-area: span 1 / span 1;
}
.vertical {
    grid-area: span 2 / span 1;
    /*-webkit-filter: grayscale(100%);
    filter: grayscale(100%);*/
}

.square {
    grid-area: span 1 / span 1;
}
#iku-component .image-description {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    font-size: 14px;
}

/*-------------------------------------------------------------*/
/*---------------------------iku gallery-----------------------*/
/*-------------------------------------------------------------*/
.iku .gallery-container {
    margin-top: 100vh;
    width: 95vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.iku-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4vh;
    width: 95vw;
    margin-top: 15vh;
    margin-bottom: 10vh;
}

.iku-gallery > li {
    flex: 1 1 auto;
    height: 45vh;

    cursor: pointer;
    position: relative;
}

.iku-gallery li .iku-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    margin: 0;
}
/*-------------------------------------------------------------*/
/*---------------------------modal-----------------------------*/
/*-------------------------------------------------------------*/

#iku-component .modal {
    background-color: black;
}

#iku-component .description {
    color: white;
}
