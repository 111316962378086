.bio {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-size: 2vh;
    line-height: 1.6;

    background-color: rgb(255, 255, 255);
}
.bio .text {
    width: 80vw;
    font-size: 2vh;
    text-align: justify;
}

.intro {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bio .text p {
    text-align: justify;
    width: 70%;
    margin: 0% 0% 1% 0%;
}

.bio .default-image-container {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bio .default-images img {
    max-height: 65vh;
    max-width: 90vw;
    object-fit: cover;
}
.bio .cv {
    margin-top: 20vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.grid-cv {
    width: 100%;
}

.bio h2 {
    font-size: 2vh;
}

.bio p {
    height: auto;
    line-height: 1.6;
}
.date {
    font-weight: bold;
}
.bio ul {
    margin: 3% 0% 5% 0%;
}

.bio li {
    display: grid;
    width: 100%;
    line-height: 2;
    grid-template-columns: 30% 70%; /* Adjust the first value (100px) to control the width of the date column */
    grid-gap: 5px; /* Adjust the gap between date and event */
    align-items: start; /* Align items at the top of each grid cell */
}

/* Media query for phones (max-width: 600px) */
@media screen and (max-width: 600px) {
    .bio .text {
        width: 90vw;
    }
}
