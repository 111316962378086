#nav-component {
    position: fixed;
    width: 100vw;
    z-index: 10;
    background-color: white;
}
.nav {
    width: 100vw;
    height: 10vh;
    background-color: white;
    border-bottom: 0.5px black solid;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    left: 0;
    gap: 3vh;
    margin: 0 1vw 0 1vw;
}

/*line under navbar*/
.nav::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 95%; /* Adjust the width to control the coverage of the border */
    height: 0.5px;
    background-color: rgb(0, 0, 0); /* Set the initial background color */

    mix-blend-mode: difference;
}

/* Johanna Odersky OR Iku*/
.nav h1 {
    all: unset;
    font-size: 2.2vh;
    letter-spacing: 0.2rem;
    position: static;
    width: 30%;
    font-weight: lighter;
}
.johannaIku {
    margin-left: 0vw;
}

/*-------------------------------------------------------------*/
/*-------------------------- menu -----------------------------*/
/*-------------------------------------------------------------*/
.menu {
    display: unset;
    width: 70%;
    display: flex;
    gap: 3vh;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}
.menu.open {
    opacity: 1; /* Show menu when open class is applied */
}
.menu-icon {
    cursor: pointer;
    padding: 0 3% 0 3%;
}
.menu-icon img {
    height: 6vh;
}
/* menu content*/
.nav h2 {
    all: unset;
    font-size: 1.4vh;
    letter-spacing: 0.2rem;
    font-family: "arial-mt";
}

.nav h2:hover {
    opacity: 0.8;
}

#nav-component .is-open {
    background-color: rgb(0, 0, 0);
    color: white;
}
#nav-component .is-open::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 95%; /* Adjust the width to control the coverage of the border */
    height: 0.5px;
    background-color: rgb(255, 255, 255); /* Set the initial background color */

    mix-blend-mode: difference;
}
a {
    all: unset;
    text-decoration: none;
    font-weight: light;
}

.nav a:hover {
    opacity: 0.8;
}

/* Media query for phones (max-width: 600px) */
@media screen and (max-width: 600px) {
    .johannaIku {
        margin-left: 4vw;
    }
    .menu-icon {
        cursor: pointer;
        padding: 0 4vw 0 3%;
    }
}
