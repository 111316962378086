.itch {
    width: 100vw;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 2vh;
    overflow-x: hidden;
    padding-top: 0;
}

.itch .intro {
    margin-top: 15vh;
}

.itch h2 {
    all: unset;
    font-size: 1.8vh;
    letter-spacing: 0.1rem;
    font-weight: bold;
    text-align: center;
    line-height: 1.6;

    width: 50%;
}

.itch .image-description {
    margin: 2% 0 5% 0;
}

.text p {
    width: 50vw;
    height: auto;
    line-height: 1.6;
    text-align: left;
    margin-bottom: 10%;
    margin-top: 10%;
}

.itch a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

.itch a:hover {
    cursor: pointer;
}

.itch .project-title {
    font-style: italic;
}

.itch .text {
    width: 40%;
    font-size: 2vh;
    text-align: justify;
    line-height: 1.7;
    margin: 3% 0% 6% 0%;
}

/*-------------------------------------------------------------*/
/*--------------------------- link to album -----------------------------*/
/*-------------------------------------------------------------*/
.iku-link {
    margin-bottom: 15vh;
}
.iku-link img {
    max-height: 70vh;
    width: auto;
}

/*-------------------------------------------------------------*/
/*--------------------------- gallery -----------------------------*/
/*-------------------------------------------------------------*/

.itch .gallery-container {
    width: 100vw;
    height: auto;

    display: flex;
    justify-content: center;
    align-items: center;
}
.itch-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4vh;
    width: 80vw;
    margin-top: 15vh;
    margin-bottom: 10vh;
}

.itch-gallery > li {
    flex: 1 1 auto;
    height: 400px;
    cursor: pointer;
    position: relative;
}

.itch-gallery li .itch-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    margin: 0;
}

/*-------------------------------------------------------------*/
/*---------------------------modal-----------------------------*/
/*-------------------------------------------------------------*/

.itch .modal {
    background-color: black;
}
/* Media query for phones (max-width: 600px) */
@media screen and (max-width: 600px) {
    .itch {
        padding-top: 12vh;
    }
    .itch .text {
        width: 90vw;
    }

    .iku-link img {
        max-height: 50vw;
        width: auto;
        margin-top: 2vh;
    }
    .itch-gallery {
        margin-top: 3vh;
        margin-bottom: 2vh;
    }
}
